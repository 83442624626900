import {WlFavoriteEvent} from "../../webcomponents/events/WlFavorite";

const hiddenCssClass = 'hidden';
const wlFavoriteComponentSelector= 'wl-favorite';
const mobileFavoritesMarker = document.querySelector('.js-favorites-marker');
const desktopFavoritesNumber = document.querySelector('.js-favorites-number');

if (mobileFavoritesMarker) {
    document.querySelectorAll(wlFavoriteComponentSelector).forEach((item) => {
        item.addEventListener(WlFavoriteEvent.type, (e) => {
            if ((e as WlFavoriteEvent).currentFavorites.listings.size > 0) {
                mobileFavoritesMarker.classList.remove(hiddenCssClass);
            } else {
                mobileFavoritesMarker.classList.add(hiddenCssClass);
            }
        });
    });
}

if (desktopFavoritesNumber) {
    document.querySelectorAll(wlFavoriteComponentSelector).forEach((item) => {
        item.addEventListener(WlFavoriteEvent.type, (e) => {
            const numberOfFavorites: number = (e as WlFavoriteEvent).currentFavorites.listings.size;
            desktopFavoritesNumber.textContent = numberOfFavorites > 0 ? numberOfFavorites.toString() : null;
        });
    });
}
