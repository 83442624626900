import {FavoriteCookie} from "../../core/FavoritesCookie";

export class WlFavoriteEvent extends Event {
    public listingId: string;
    public isSelected: boolean;
    public currentFavorites: FavoriteCookie;

    static type: string = 'wl-favorite-selected';

    constructor(listingId: string, isSelected: boolean, currentFavorites: FavoriteCookie) {
        super(WlFavoriteEvent.type, {bubbles: true, composed: true});
        this.listingId = listingId;
        this.isSelected = isSelected;
        this.currentFavorites = currentFavorites;
    }
}